<div class="radial-app-container flex h-screen flex-row">
  <div class="radial-left-section flex flex-column row-gap-4 p-4">
    <div class="radial-logo cursor-pointer">
      <img src="../assets/images/radialLogo.png" alt="Radial Client Portal" />
    </div>
    <div class="radial-menu flex flex-column row-gap-4">
      @if(sitesFetched && userDetailsFetched){
      <button (click)="gotoRealtimedashoard()" class="btn-secondary-lg">
        goto Realtimedashboard
      </button>
      <button (click)="gotoReportPage()" class="btn-secondary-lg">
        goto reportPage
      </button>
      <button (click)="goToSla()" class="btn-secondary-lg">goto SLA</button>
      <button (click)="goToReturn()" class="btn-secondary-lg">
        goto Return Details
      </button>
      <button (click)="goToInventory()" class="btn-secondary-lg">
        goto inventory Details
      </button>
      <button (click)="goToInbound()" class="btn-secondary-lg">
        goto inbound page
      </button>
      <button (click)="gotoHistoricDashboard()" class="btn-secondary-lg">
        goto HistoricDashboard
      </button>
      <button (click)="gotoOutbound()" class="btn-secondary-lg">
        goto Outbound
      </button>
      } @else{
      <div class="flex">
        <p-skeleton size="2rem" styleClass="mr-2" />
        <p-skeleton width="10rem" height="2rem" />
      </div>
      <div class="flex">
        <p-skeleton size="2rem" styleClass="mr-2" />
        <p-skeleton width="10rem" height="2rem" />
      </div>
      <div class="flex">
        <p-skeleton size="2rem" styleClass="mr-2" />
        <p-skeleton width="10rem" height="2rem" />
      </div>
      <div class="flex">
        <p-skeleton size="2rem" styleClass="mr-2" />
        <p-skeleton width="10rem" height="2rem" />
      </div>
      }
    </div>
  </div>
  <div class="radial-right-section flex flex-column">
    <div
      class="radial-header p-3 flex flex-row justify-content-end align-items-center"
    >
      <div
        class="radial-user-info font-ubuntu-regular cursor-pointer"
        #userInfoContainer
      >
        @if(!loggedInUserInfo){
        <p-skeleton width="10rem" height="2rem" />
        } @else{
        <div (click)="userInfoOverlay.toggle($event)">
          <svg
            class="vertical-align-middle"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="user">
              <path
                id="Vector"
                d="M10 10.2083C9.38193 10.2083 8.77775 10.025 8.26384 9.6816C7.74994 9.33822 7.3494 8.85016 7.11288 8.27914C6.87635 7.70812 6.81447 7.07979 6.93505 6.4736C7.05563 5.86741 7.35325 5.31058 7.79029 4.87354C8.22733 4.43651 8.78415 4.13888 9.39034 4.0183C9.99654 3.89772 10.6249 3.95961 11.1959 4.19613C11.7669 4.43265 12.255 4.83319 12.5983 5.3471C12.9417 5.861 13.125 6.46519 13.125 7.08325C13.125 7.91205 12.7958 8.70691 12.2097 9.29296C11.6237 9.87901 10.8288 10.2083 10 10.2083ZM10 5.20825C9.62916 5.20825 9.26665 5.31822 8.95831 5.52425C8.64996 5.73028 8.40964 6.02311 8.26773 6.36572C8.12581 6.70833 8.08868 7.08533 8.16103 7.44905C8.23338 7.81276 8.41195 8.14685 8.67418 8.40908C8.9364 8.6713 9.27049 8.84988 9.63421 8.92223C9.99792 8.99457 10.3749 8.95744 10.7175 8.81553C11.0601 8.67361 11.353 8.43329 11.559 8.12495C11.765 7.81661 11.875 7.45409 11.875 7.08325C11.875 6.58597 11.6775 6.10906 11.3258 5.75743C10.9742 5.4058 10.4973 5.20825 10 5.20825Z"
                fill="#07073A"
              />
              <path
                id="Vector_2"
                d="M15.8337 16.0416C15.6686 16.0394 15.5108 15.9729 15.3941 15.8561C15.2774 15.7394 15.2108 15.5817 15.2087 15.4166C15.2087 13.7916 14.3253 12.7083 10.0003 12.7083C5.67533 12.7083 4.79199 13.7916 4.79199 15.4166C4.79199 15.5823 4.72614 15.7413 4.60893 15.8585C4.49172 15.9757 4.33275 16.0416 4.16699 16.0416C4.00123 16.0416 3.84226 15.9757 3.72505 15.8585C3.60784 15.7413 3.54199 15.5823 3.54199 15.4166C3.54199 11.4583 8.06699 11.4583 10.0003 11.4583C11.9337 11.4583 16.4587 11.4583 16.4587 15.4166C16.4565 15.5817 16.39 15.7394 16.2732 15.8561C16.1565 15.9729 15.9987 16.0394 15.8337 16.0416Z"
                fill="#07073A"
              />
            </g>
          </svg>
          {{
            welcome +
              loggedInUserInfo?.firstName +
              " " +
              loggedInUserInfo?.lastName
          }}
        </div>
        }
      </div>
      <div class="radail-client-warehouseDD">
        @if(!(userDetailsFetched && sitesFetched)){
        <p-skeleton width="10rem" height="2rem" styleClass="ml-2" />
        } @else{
        <rcp-client-warehouse-dropdown
          (clientWarehouseChange)="onClientWarehouseChange($event)"
        ></rcp-client-warehouse-dropdown>
        }
      </div>
    </div>
    <div class="radial-main">
      @if(sitesFetched && userDetailsFetched){
      <router-outlet></router-outlet>
      }
    </div>
  </div>
</div>
<p-overlayPanel
  #userInfoOverlay
  styleClass="userInfoPopup"
  [appendTo]="userInfoContainer"
>
  <ng-template pTemplate="content">
    <div class="flex flex-column userInfoTemplate">
      <div class="userInfo flex column-gap-2">
        <div class="icon">
          <i class="pi pi-user" style="font-size: 1.25rem"></i>
        </div>
        <div class="info">{{ loggedInUserInfo.role || "--" }}</div>
      </div>
      <div class="userInfo flex column-gap-2">
        <div class="icon">
          <i class="pi pi-envelope" style="font-size: 1.25rem"></i>
        </div>
        <div class="info">{{ loggedInUserInfo.mail || "--" }}</div>
      </div>
      <div
        class="userInfo logout flex column-gap-2 cursor-pointer"
        (click)="logOut()"
      >
        <div class="icon">
          <i class="pi pi-sign-out" style="font-size: 1.25rem"></i>
        </div>
        <div class="info">Logout</div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
