import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
@Component({
  selector: 'app-change-address-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    FloatLabelModule,
    CommonModule,
  ],
  templateUrl: './change-address-form.component.html',
  styleUrl: './change-address-form.component.scss',
  providers: [DialogService, DynamicDialogRef],
})
export class ChangeAddressFormComponent {
  addressForm: FormGroup;
  isDisplay: boolean = false;
  private order: any;
  changeaddress = 'Change address';
  customername = 'Customer Name';
  newaddress = 'Enter New Address';
  firstName = 'First name';
  firstnamevalidator_1 = 'First Name is required.';
  firstnamevalidator_2 = 'First Name must be at least 2 characters long.';
  firstnamevalidator_3 = 'First Name should only contain letters and spaces..';
  lastName = 'Last name';
  lastNameevalidator_1 = 'Last Name is required.';
  lastNameevalidator_2 = 'Last Name must be at least 2 characters long.';
  lastNameevalidator_3 = 'Last Name should only contain letters and spaces..';
  addressLine1 = 'Address line 1';
  addressLine1_validators_1 = 'Address Line1 is required';
  addressLine1_validators_2 = 'Minimum 3 characters are required.';
  addressLine2 = 'Address line 2';
  addressLine2_validators_1 = 'Minimum  characters are required.';
  postcode = 'Post Code';
  postcodevalidator_1 = 'Postal Code is required.';
  postcodevalidator_2 = 'Minimun 2 number is required';
  postcodevalidator_3 = 'Post code should only contain numbers .';
  city = 'City';
  city_validators_1 = 'City is required';
  city_validators_2 = 'Minimum 2 character are required.';
  country = 'Country';
  country_validators_1 = 'Country is required';
  country_validators_2 = 'Minimum 2 character are required.';
  constructor(private fb: FormBuilder, private router: Router) {
    this.addressForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('[A-Za-z ]+'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[a-zA-Z ]*$'),
        ],
      ],
      addressLine1: ['', [Validators.required, Validators.minLength(3)]],
      addressLine2: ['', [Validators.minLength(2)]],
      city: ['', [Validators.required, Validators.minLength(2)]],
      postcode: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[0-9]{5}$'),
        ],
      ],
      country: ['', [Validators.required, Validators.minLength(2)]],
    });
  }
  ngOnInit() {
    this.order = history.state.order;
    if (this.order) {
      this.addressForm.patchValue({
        firstName: this.order.firstName || '',
        lastName: this.order.lastName || '',
        addressLine1: this.order.addressLine1 || '',
        addressLine2: this.order.addressLine2 || '',
        postcode: this.order.postcode || '',
        city: this.order.city || '',
        country: this.order.country || '',
      });
      this.isDisplay = true;
    }
  }
  onCancel() {
    this.router.navigate(['/orderDetails']);
  }
  onSubmit() {
    if (this.addressForm.valid) {
      this.router.navigate(['/orderDetails'], {
        state: { updatedOrder: this.addressForm.value },
      });
      this.isDisplay = false;
    }
  }
}
