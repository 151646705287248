<p-dialog
  [(visible)]="display"
  [modal]="true"
  [showHeader]="false"
  [closable]="false"
>
  <div class="flex flex-column gap-5">
    <div class="flex flex-column">
      <div class="dialog-header">
        {{ additem }}
        <button pButton class="close-btn" (click)="cancel()">
          <i class="close-icon"></i>
        </button>
      </div>
      <div class="flex flex-column gap-5">
        <div class="flex flex-column">
          <p-floatLabel class="input-box-with-icon">
            <input
              type="text"
              class="input-box-search"
              id="sku-number"
              type="text"
              pInputText
              [(ngModel)]="skuNumber"
              placeholder=" "
            />
            <label for="sku-number">{{ skuNum }}</label>
            <i
              class="search-icon"
              (click)="searchSKU()"
              (keydown)="onKeyDown($event)"
            >
            </i>
          </p-floatLabel>
        </div>
        <div class="formgrid grid flex flex-row gap-2">
          <div class="col">
            <p-floatLabel>
              <input
                id="quantity"
                type="number"
                class="input-box"
                pInputText
                [(ngModel)]="quantity"
                placeholder=" "
              />
              <label for="quantity">{{ quantitty }}</label>
            </p-floatLabel>
          </div>
          <div class="col">
            <p-floatLabel>
              <input
                id="unit-price"
                type="number"
                pInputText
                [(ngModel)]="unitPrice"
                placeholder=" "
                class="input-box"
              />
              <label for="unit-price">{{ unitcost }}</label>
            </p-floatLabel>
          </div>
        </div>
        <div>
          <div
            class="flex flex-row gap-4 justify-content-end"
            *ngIf="!searchAttempted"
          >
            <button
              pButton
              type="button"
              label="Cancel"
              (click)="cancel()"
              class="btn-secondary-lg"
            ></button>
            <button
              pButton
              type="button"
              label="Search"
              class="btn-primary-lg"
              (click)="searchSKU()"
            ></button>
          </div>
        </div>
      </div>
      <!-- Conditionally show SKU details or an error message -->
      <div>
        <div *ngIf="skuFound">
          <div class="flex flex-column gap-3">
            <div class="flex flex-column gap-2">
              <div class="font-lg">{{ skuDesc }}</div>
              <div>{{ skuDetails.description }}</div>
            </div>
            <div class="flex flex-column gap-2">
              <div class="font-lg">{{ clientSkuNum }}</div>
              <div>{{ skuDetails.clientSku }}</div>
            </div>
            <div class="flex flex-column gap-2">
              <div class="font-lg">{{ availQuant }}</div>
              <div>{{ skuDetails.availableQuantities }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf="!skuFound && searchAttempted" class="sku-not-found">
          <div class="flex flex-column">
            <div class="flex flex-column gap-3">
              <div class="flex flex-row justify-content-center">
                <i class="packageopen-icon"></i>
              </div>
              <div class="font-large">{{ noSKU_1 }}</div>
            </div>
            <div class="font-small">{{ noSku_2 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="skuFound" class="flex flex-row gap-2 justify-content-end">
      <button
        pButton
        type="button"
        label="Cancel"
        (click)="cancel()"
        class="btn-secondary-lg"
      ></button>
      <button
        pButton
        type="button"
        label="Add line"
        class="btn-primary-lg"
        (click)="addLine()"
      ></button>
    </div>
  </div>
</p-dialog>
<button pButton label="Show Add Item Dialog" (click)="display = true"></button>
