<p-dialog
  [(visible)]="isDisplay"
  [modal]="true"
  [closable]="true"
  (onHide)="onCancel()"
  [showHeader]="false"
>
  <div class="flex flex-column py-2 pb-5">
    <div class="flex flex-column gap-5 custom-dialog-color">
      <div
        class="flex flex-row justify-content-between align-items-center w-100 pt-5"
      >
        <div class="flex-1 text-center font-ubuntu-medium text-2xl">
          {{ changeaddress }}
        </div>
        <div>
          <button pButton (click)="onCancel()" class="custom-btn">
            <i class="close-icon"></i>
          </button>
        </div>
      </div>
      <form [formGroup]="addressForm">
        <div class="flex flex-column justify-content-between gap-5">
          <div class="flex flex-column gap-5">
            <div
              class="font-ubuntu-medium flex flex-column font-medium text-lg"
            >
              {{ customername }}
            </div>
            <div class="flex flex-column gap-4">
              <div class="flex flex-column">
                <p-floatLabel>
                  <input
                    id="firstName"
                    type="text"
                    pInputText
                    formControlName="firstName"
                    placeholder=" "
                    class="input-box"
                  />
                  <label for="firstName">{{ firstName }}</label>
                </p-floatLabel>
                <div
                  *ngIf="
                    addressForm.get('firstName')?.invalid &&
                    (addressForm.get('firstName')?.dirty ||
                      addressForm.get('firstName')?.touched)
                  "
                >
                  <small
                    *ngIf="addressForm.get('firstName')?.hasError('required')"
                    class="p-error"
                    >{{ firstnamevalidator_1 }}.</small
                  >
                  <small
                    *ngIf="addressForm.get('firstName')?.hasError('minlength')"
                    class="p-error"
                    >{{ firstnamevalidator_2 }}</small
                  >
                  <small
                    *ngIf="addressForm.get('firstName')?.hasError('pattern')"
                    class="p-error"
                    >{{ firstnamevalidator_3 }}</small
                  >
                </div>
              </div>
              <div class="">
                <p-floatLabel>
                  <input
                    id="lastName"
                    type="text"
                    pInputText
                    formControlName="lastName"
                    placeholder=" "
                    class="input-box"
                  />
                  <label for="lastName">{{ lastName }}</label>
                </p-floatLabel>
                <div
                  *ngIf="
                    addressForm.get('lastName')?.invalid &&
                    (addressForm.get('lastName')?.dirty ||
                      addressForm.get('lastName')?.touched)
                  "
                >
                  <small
                    *ngIf="addressForm.get('lastName')?.hasError('required')"
                    class="p-error"
                    >{{ lastNameevalidator_1 }}.</small
                  >
                  <small
                    *ngIf="addressForm.get('lastName')?.hasError('minlength')"
                    class="p-error"
                    >{{ lastNameevalidator_2 }}</small
                  >
                  <small
                    *ngIf="addressForm.get('lastName')?.hasError('pattern')"
                    class="p-error"
                    >{{ lastNameevalidator_3 }}.</small
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-column justify-content-between gap-5">
            <div class="flex flex-column gap-4">
              <div
                class="font-ubuntu-medium flex flex-column font-medium text-lg"
              >
                {{ newaddress }}
              </div>
              <div class="flex flex-column gap-4">
                <div class="flex flex-column">
                  <p-floatLabel>
                    <input
                      id="addressLine1"
                      type="text"
                      pInputText
                      formControlName="addressLine1"
                      placeholder=" "
                      class="input-box"
                    />
                    <label for="addressLine1">{{ addressLine1 }}</label>
                  </p-floatLabel>
                  <div
                    *ngIf="
                      addressForm.get('addressLine1')?.invalid &&
                      (addressForm.get('addressLine1')?.dirty ||
                        addressForm.get('addressLine1')?.touched)
                    "
                  >
                    <small
                      *ngIf="
                        addressForm.get('addressLine1')?.hasError('required')
                      "
                      class="p-error"
                      >{{ addressLine1_validators_1 }}</small
                    >
                    <small
                      *ngIf="
                        addressForm.get('addressLine1')?.hasError('minlength')
                      "
                      class="p-error"
                    >
                      {{ addressLine1_validators_2 }}</small
                    >
                  </div>
                </div>
                <div class=" ">
                  <p-floatLabel>
                    <input
                      id="addressLine2"
                      type="text"
                      pInputText
                      formControlName="addressLine2"
                      placeholder=" "
                      class="input-box"
                    />
                    <label for="addressLine2">{{ addressLine2 }}</label>
                  </p-floatLabel>
                  <div
                    *ngIf="
                      addressForm.get('addressLine2')?.invalid &&
                      (addressForm.get('addressLine2')?.dirty ||
                        addressForm.get('addressLine2')?.touched)
                    "
                  >
                    <small
                      *ngIf="
                        addressForm.get('addressLine2')?.hasError('minlength')
                      "
                      class="p-error"
                    >
                      {{ addressLine2_validators_1 }}</small
                    >
                  </div>
                </div>
                <div class="formgrid grid flex flex-row">
                  <div class="col">
                    <p-floatLabel>
                      <input
                        id="postcode"
                        type="text"
                        pInputText
                        formControlName="postcode"
                        placeholder=" "
                        class="input-box"
                      />
                      <label for="postcode">{{ postcode }}</label>
                    </p-floatLabel>
                    <div
                      *ngIf="
                        addressForm.get('postcode')?.invalid &&
                        (addressForm.get('postcode')?.dirty ||
                          addressForm.get('postcode')?.touched)
                      "
                    >
                      <small
                        *ngIf="
                          addressForm.get('postcode')?.hasError('required')
                        "
                        class="p-error"
                      >
                        {{ postcodevalidator_1 }}</small
                      >
                      <small
                        *ngIf="
                          addressForm.get('postcode')?.hasError('minlength')
                        "
                        class="p-error"
                      >
                        {{ postcodevalidator_2 }}</small
                      >
                      <small
                        *ngIf="addressForm.get('postcode')?.hasError('pattern')"
                        class="p-error"
                        >{{ postcodevalidator_1 }}</small
                      >
                    </div>
                  </div>
                  <div class="col">
                    <p-floatLabel>
                      <input
                        id="city"
                        type="text"
                        pInputText
                        formControlName="city"
                        placeholder=" "
                        class="input-box"
                      />
                      <label for="city">{{ city }}</label>
                    </p-floatLabel>
                    <div
                      *ngIf="
                        addressForm.get('city')?.invalid &&
                        (addressForm.get('city')?.dirty ||
                          addressForm.get('city')?.touched)
                      "
                    >
                      <small
                        *ngIf="addressForm.get('city')?.hasError('required')"
                        class="p-error"
                      >
                        {{ city_validators_1 }}</small
                      >
                      <small
                        *ngIf="addressForm.get('city')?.hasError('minlength')"
                        class="p-error"
                      >
                        {{ city_validators_2 }}</small
                      >
                    </div>
                  </div>
                </div>
                <div class="">
                  <p-floatLabel>
                    <input
                      id="country"
                      type="text"
                      pInputText
                      formControlName="country"
                      placeholder=" "
                      class="input-box"
                    />
                    <label for="country">{{ country }}</label>
                  </p-floatLabel>
                  <div
                    *ngIf="
                      addressForm.get('country')?.invalid &&
                      (addressForm.get('country')?.dirty ||
                        addressForm.get('country')?.touched)
                    "
                  >
                    <small
                      *ngIf="addressForm.get('country')?.hasError('required')"
                      class="p-error"
                    >
                      {{ country_validators_1 }}</small
                    >
                    <small
                      *ngIf="addressForm.get('country')?.hasError('minlength')"
                      class="p-error"
                    >
                      {{ country_validators_1 }}</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="flex flex-row gap-4 justify-content-end">
                <button
                  pButton
                  type="button"
                  label="Cancel"
                  (click)="onCancel()"
                  class="btn-secondary-lg"
                ></button>
                <button
                  pButton
                  type="submit"
                  label="Save"
                  class="btn-primary-lg"
                  (click)="onSubmit()"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
