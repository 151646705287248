<div class="card flex justify-content-center Cancel-dialog-box">
  <p-confirmDialog [visible]="display" (hide)="display = false">
    <ng-template pTemplate="message" let-message>
      <div
        class="flex flex-column align-items-center w-full border-bottom-1 surface-border"
      >
        <i class="icon-xl icon-close-bg"></i>
        <span id="header-text">{{ message.header }}</span>
        <p>{{ message.message }}</p>
      </div>
    </ng-template>
    <ng-template pTemplate="footer" style="display: flex">
      <div class="flex flex-row justify-content-center gap-4">
        <button
          type="button"
          pButton
          label="No, Don''t"
          class="btn-secondary-lg"
          (click)="onCancel()"
        ></button>
        <button
          type="button"
          pButton
          label="Yes, Cancel"
          class="btn-red-lg"
          (click)="onSave()"
        ></button>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
