import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { Subject, takeUntil } from 'rxjs';
import { InboundService } from '../../services/inbound-service/inbound.service';
import { UserService } from '../../services/user-service/user.service';
import { DateSelectorComponent } from '../../shared/components/date-selector/date-selector.component';
import { GridComponent } from '../../shared/components/grid/grid.component';
import { PaginatorComponent } from '../../shared/components/paginator/paginator.component';
import { inboundSummeryViewHeader } from './inbound-header-columns';
@Component({
  selector: 'rcp-inbound-metrics',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DateSelectorComponent,
    ReactiveFormsModule,
    DropdownModule,
    GridComponent,
    PaginatorComponent,
    InputTextModule,
  ],
  templateUrl: './inbound-metrics.component.html',
  styleUrl: './inbound-metrics.component.scss',
  providers: [DatePipe],
})
export class InboundMetricsComponent implements OnInit {
  @ViewChild(GridComponent) childComponent!: GridComponent;
  @ViewChild('paginator', { static: false }) paginator: PaginatorComponent;
  destroy$: Subject<boolean> = new Subject<boolean>();
  first: number = 0;
  rows: number = 50;
  totalRecords: number = 0;
  isShowFilterAndSorting: boolean = true;
  isShowSorting: boolean = true;
  isShowFilter: boolean = true;
  headerColumns: any;
  selectedReceivedDate: Date[] = null;
  selectedInterfaceDate: Date[] = null;
  defaultStartDate: Date = null;
  defaultEndDate: Date = null;
  maxReceivedDate: Date = new Date();
  maxInterfaceDate: Date = new Date();
  maxDateOfFirstSelectionReceivedDate: Date | undefined;
  maxDateOfFirstSelectionInterfaceDate: Date | undefined;
  isClearLastReceivedDate: boolean = false;
  isClearInterfaceDate: boolean = false;
  isRequiredStatus: boolean = true;
  selectedOrderNumber: string = null;
  selectedClientSku: string = null;
  parentRowField: any;
  childRowField: any;
  gridData: any[] = [];
  inboundListViewData = [];
  innerChildRowFieldArray = [];
  receivedDateFromStringFormat: string = null;
  receivedDateToSringFormat: string = null;
  interfacedDateFromStringFormat: string = null;
  interfacedDateToSringFormat: string = null;
  welcomeLabel: string = 'Welcome';
  inboundPageTitle: string = 'Inbound';
  receivedDatePlaceholder: string = 'Received date';
  interfaceDatePlaceholder: string = 'Interface date';
  orderNumberPlaceholder: string = 'Order number';
  applyFiltersBtnLabel: string = 'Apply filters';
  resetFiltersBtnLabel: string = 'Reset filters';
  constructor(
    private datePipe: DatePipe,
    private cdf: ChangeDetectorRef,
    private inboundService: InboundService,
    public userService: UserService
  ) {}
  ngOnInit(): void {
    this.gridHeaderData();
    this.autoCallAPIwithTodayOnDashboardIconClick();
  }
  // Get the grid header data
  gridHeaderData() {
    this.headerColumns = inboundSummeryViewHeader;
  }
  autoCallAPIwithTodayOnDashboardIconClick() {
    if (this.userService.isClickedOnDashboardIcon) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.defaultStartDate = today;
      this.defaultEndDate = today;
      this.selectedReceivedDate = [this.defaultStartDate, this.defaultEndDate];
      this.appyFilter();
      this.userService.isClickedOnDashboardIcon = false;
    }
  }
  // Handle paginator event
  onPageChange(event?: any) {
    if (event) {
      const start = event.first;
      const end = event.first + event.rows;
      this.paginator.changeValueForPaginator(start);
      this.childComponent.handleParentEvent(start, end);
    } else {
      this.paginator.changeValueForPaginator(this.first);
      this.childComponent.handleParentEvent(this.first, this.rows);
    }
  }
  setOrderNumber() {
    if (this.selectedOrderNumber) {
      if (this.selectedReceivedDate) {
        this.selectedReceivedDate = null;
        this.isClearLastReceivedDate = true;
      }
      if (this.selectedInterfaceDate) {
        this.selectedInterfaceDate = null;
        this.isClearInterfaceDate = true;
      }
      this.selectedClientSku = null;
    }
  }
  setClientSku() {
    if (this.selectedClientSku) {
      if (this.selectedReceivedDate) {
        this.selectedReceivedDate = null;
        this.isClearLastReceivedDate = true;
      }
      if (this.selectedInterfaceDate) {
        this.selectedInterfaceDate = null;
        this.isClearInterfaceDate = true;
      }
    }
    this.selectedOrderNumber = null;
  }
  setLastReceivedDate(value: Date[]) {
    this.selectedReceivedDate = value;
    if (this.selectedReceivedDate.length > 0) {
      if (this.selectedInterfaceDate) {
        this.isClearInterfaceDate = true;
      }
      this.selectedInterfaceDate = null;
      this.selectedOrderNumber = null;
      this.selectedClientSku = null;
    }
  }
  setInterfaceDate(value: Date[]) {
    this.selectedInterfaceDate = value;
    if (this.selectedInterfaceDate.length > 0) {
      if (this.selectedReceivedDate) {
        this.isClearLastReceivedDate = true;
      }
      this.selectedReceivedDate = null;
      this.selectedOrderNumber = null;
      this.selectedClientSku = null;
    }
  }
  clearLastReceivedDate(event: any) {
    this.selectedReceivedDate = null;
    this.isClearLastReceivedDate = false;
    this.cdf.detectChanges();
  }
  clearInterfaceDate(event: any) {
    this.selectedInterfaceDate = null;
    this.isClearInterfaceDate = false;
    this.cdf.detectChanges();
  }
  onStartDateSelectedOfReceivedDate(startDate: Date) {
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 31);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.maxDateOfFirstSelectionReceivedDate =
      maxDate > today ? today : maxDate;
  }
  onStartDateSelectedOfInterfaceDate(startDate: Date) {
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 31);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.maxDateOfFirstSelectionInterfaceDate =
      maxDate > today ? today : maxDate;
  }
  resetFiltersData() {
    if (this.selectedReceivedDate?.length > 0) {
      this.isClearLastReceivedDate = true;
    }
    if (this.selectedInterfaceDate?.length > 0) {
      this.isClearInterfaceDate = true;
    }
    this.selectedReceivedDate = null;
    this.selectedInterfaceDate = null;
    this.selectedOrderNumber = null;
    this.selectedClientSku = null;
    this.inboundListViewData = null;
    this.maxDateOfFirstSelectionReceivedDate = null;
    this.maxDateOfFirstSelectionInterfaceDate = null;
    this.gridData = [];
    this.totalRecords = 0;
    this.childComponent.emptyGridOnReset();
    this.cdf.detectChanges();
  }
  appyFilter() {
    this.gridData = [];
    this.totalRecords = 0;
    this.receivedDateFromStringFormat = null;
    this.receivedDateToSringFormat = null;
    this.interfacedDateFromStringFormat = null;
    this.interfacedDateToSringFormat = null;
    if (this.selectedReceivedDate) {
      this.receivedDateFromStringFormat = this.datePipe.transform(
        this.selectedReceivedDate[0],
        'yyyy-MM-dd'
      );
      this.receivedDateToSringFormat = this.datePipe.transform(
        this.selectedReceivedDate[1],
        'yyyy-MM-dd'
      );
    }
    if (this.selectedInterfaceDate) {
      this.interfacedDateFromStringFormat = this.datePipe.transform(
        this.selectedInterfaceDate[0],
        'yyyy-MM-dd'
      );
      this.interfacedDateToSringFormat = this.datePipe.transform(
        this.selectedInterfaceDate[1],
        'yyyy-MM-dd'
      );
    }
    const payLoad = {
      poNumber: this.selectedOrderNumber,
      clientSku: this.selectedClientSku,
      receivedDateFrom: this.receivedDateFromStringFormat,
      receivedDateTo: this.receivedDateToSringFormat,
      interfacedDateFrom: this.interfacedDateFromStringFormat,
      interfacedDateTo: this.interfacedDateToSringFormat,
      sortBy: null,
      sortDirection: null,
      offset: 0,
      pageLimit: 1000,
    };
    this.getFilterData(payLoad);
  }
  addDataInGridTable() {
    this.gridData = [];
    this.inboundListViewData.forEach((item: any) => {
      this.innerChildRowFieldArray = [];
      this.parentRowField = {
        client_sku: item.client_sku ? item.client_sku : '-',
        sku_description: item.sku_description ? item.sku_description : '-',
        po_number: item.po_number ? item.po_number : '-',
        rcvd_details:
          item.rcvd_details.length > 1
            ? '-'
            : item.rcvd_details[0].goods_rcvd_date
            ? item.rcvd_details[0].goods_rcvd_date
            : '-',
        purchase_orders_dttm: item.purchase_orders_dttm
          ? item.purchase_orders_dttm
          : '-',
        goods_rcvd_qty: item.goods_rcvd_qty ? item.goods_rcvd_qty : 0,
        expected_qty: item.expected_qty ? item.expected_qty : 0,
        status:
          Math.floor((100 * (item.goods_rcvd_qty ?? 0)) / item.expected_qty) +
          '%',
      };
      if (item.rcvd_details.length > 1) {
        item.rcvd_details?.forEach((childItem: any) => {
          this.childRowField = {
            rcvd_details: childItem.goods_rcvd_date
              ? childItem.goods_rcvd_date
              : '-',
            goods_rcvd_qty: childItem.goods_rcvd_qty
              ? childItem.goods_rcvd_qty
              : 0,
          };
          this.innerChildRowFieldArray.push({
            data: this.childRowField,
          });
        });
      }
      this.gridData.push({
        data: this.parentRowField,
        children: this.innerChildRowFieldArray,
      });
    });
    this.totalRecords = this.gridData.length;
  }
  getFilterData(payLoad: any) {
    this.inboundService
      .getInboundList(payLoad)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.inboundListViewData = data.response;
          this.addDataInGridTable();
          this.onPageChange();
        },
        error: () => {
          console.error('error in fetching report data');
        },
      });
  }
}
