<div class="rcp-component-ref">
  <h1>This is default component</h1>
  <p-card header="Calendar" class="mb-1">
    <rcp-date-selector></rcp-date-selector>
  </p-card>
  <br />
  <p-card header="Complex Grid">
    <rcp-tab-filter></rcp-tab-filter>
  </p-card>
  <br />
  <div header="Order Details">
    <app-orderdetails></app-orderdetails>
  </div>
  <br />
  <p-card header="Buttons">
    <div class="buttons flex flex-column">
      <div class="largeButtons flex-1">
        <div class="bheading">Large Buttons</div>
        <div class="buttonsGrp">
          <div class="lb">
            <div class="lable">class="btn-primary-lg"</div>
            <button type="button" class="btn-primary-lg">Button</button>
          </div>
          <div class="lb">
            <div class="lable">class="btn-primary-lg" [disabled]="true"</div>
            <button type="button" class="btn-primary-lg" [disabled]="true">
              Button
            </button>
          </div>
          <div class="lb">
            <div class="lable">class="btn-secondary-lg"</div>
            <button type="button" class="btn-secondary-lg">Button</button>
          </div>
          <div class="lb">
            <div class="lable">class="btn-secondary-lg" [disabled]="true"</div>
            <button type="button" class="btn-secondary-lg" [disabled]="true">
              Button
            </button>
          </div>
          <div class="lb">
            <div class="lable">class="btn-sd-lg"</div>
            <button type="button" class="btn-sd-lg">Button</button>
          </div>
        </div>
      </div>
      <div class="smallButtons flex-1">
        <div class="bheading">Small Buttons</div>
        <div class="buttonsGrp">
          <div class="sb">
            <div class="lable">class="btn-primary-sm"</div>
            <button type="button" class="btn-primary-sm">Button</button>
          </div>
          <div class="sb">
            <div class="lable">class="btn-primary-sm"</div>
            <button type="button" class="btn-primary-sm" [disabled]="true">
              Button
            </button>
          </div>
          <div class="sb">
            <div class="lable">class="btn-secondary-sm"</div>
            <button type="button" class="btn-secondary-sm">Button</button>
          </div>
          <div class="sb">
            <div class="lable">class="btn-secondary-sm"</div>
            <button type="button" class="btn-secondary-sm" [disabled]="true">
              Button
            </button>
          </div>
          <div class="sb">
            <div class="lable">class="btn-sd-sm"</div>
            <button type="button" class="btn-sd-sm">Button</button>
          </div>
        </div>
      </div>
      <div class="iconButtons flex-1">
        <div class="bheading">Icon Buttons</div>
        <div class="buttonsGrp">
          <div class="ib">
            <div class="lable">class="btn-icon-lg"</div>
            <button type="button" class="btn-icon-lg">
              <span class="plus-icon"></span>
              <span class="icon-with-text"> Add a line</span>
            </button>
          </div>
          <div class="ib">
            <div class="lable">class="btn-icon-sm"</div>
            <button type="button" class="btn-icon-sm">
              <span class="plus-icon"></span>
              <span class="icon-with-text"> Add a line</span>
            </button>
          </div>
          <div class="ib">
            <div class="lable">class="btn-edit-icon-lg"</div>
            <button type="button" class="btn-edit-icon-lg">
              <span class="pencil-icon"></span>
              <span class="icon-with-text">Edit</span>
            </button>
          </div>
          <div class="ib">
            <div class="lable">class="btn-edit-icon-sm"</div>
            <button type="button" class="btn-edit-icon-sm">
              <span class="pencil-icon"></span>
              <span class="icon-with-text">Edit</span>
            </button>
          </div>
        </div>
      </div>
      <div class="redButtons flex-1">
        <div class="bheading">Red Buttons</div>
        <div class="buttonsGrp">
          <div class="rb">
            <div class="label">class="btn-red-lg"</div>
            <button type="button" class="btn-red-lg">Button</button>
          </div>
          <div class="rb">
            <div class="label">class="btn-red-sm"</div>
            <button type="button" class="btn-red-sm">Button</button>
          </div>
          <div class="rb">
            <div class="label">class="btn-cancel-sm"</div>
            <button type="button" class="btn-cancel-sm">Cancel order</button>
          </div>
        </div>
      </div>
    </div>
  </p-card>
  <br />
  <p-card header="Input">
    <div class="inputs flex flex-wrap gap-2">
      <div class="input">
        <div class="lable">class="input-box" disabled</div>
        <input type="text" class="input-box" disabled />
      </div>
      <div class="input">
        <div class="lable">class="input-box inputbox-error"</div>
        <input type="text" class="input-box inputbox-error" />
      </div>
      <div class="input">
        <div class="lable">class="input-box" placeholder="First Name"</div>
        <input type="text" class="input-box" placeholder="First Name" />
      </div>
      <div class="input">
        <div class="lable">class="input-box-search"</div>
        <div class="input-box-with-icon">
          <input type="text" class="input-box-search" placeholder="Search" />
          <span class="search-icon"></span>
        </div>
      </div>
      <div class="input">
        <div class="lable">class="input-box" value="Add value" readonly</div>
        <input type="text" class="input-box" value="Add value" readonly />
      </div>
      <div class="input">
        <div class="lable">class="input-box" value="Add value"</div>
        <input type="text" class="input-box" value="Add value" disabled />
      </div>
    </div>
  </p-card>
  <br />
  <p-card header="Labels">
    <div class="Labels flex flex-wrap gap-2">
      <div class="Labels">
        <div class="label-with-icon">
          <span class="label">PO Number: 98645 <i class="label-icon"></i></span>
        </div>
        <br />
        <br />
        <div class="label-with-icon">
          <span class="label"
            >Interface date: 2024.02.01 <i class="label-icon"></i
          ></span>
        </div>
        <br />
        <br />
        <div class="simple-label">
          <span class="label-name">Label</span>
        </div>
        <br />
        <br />
        <div class="filter-label">
          <span class="label">Label<i class="label-icon"></i></span>
        </div>
        <br />
        <br />
        <div class="filter-label-blue">
          <span class="label">Label<i class="label-icon close-icon"></i></span>
        </div>
      </div>
    </div>
  </p-card>
  <br />
  <p-card header="Radio">
    <div class="Radio flex flex-wrap gap-2">
      <div class="Radio">
        <div class="radio-button">
          <input
            type="radio"
            id="inbound"
            name="name"
            value="inbound"
            checked
          />
          <label for="inbound" class="radio-label">Inbound</label>
          <input type="radio" id="inventory" name="name" value="inventory" />
          <label for="inventory" class="radio-label">Inventory</label>
        </div>
      </div>
    </div>
  </p-card>
  <br />
  <p-card>
    <div class="status">
      <div>
        <span class="status-badge received">
          Received
          <i class="status-icon circle-check"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge in-process">
          In process
          <i class="status-icon inprocess-icon"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge despatched">
          Despatched
          <i class="status-icon circle-check"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge in-transit">
          In transit
          <i class="status-icon circle-check"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge out-for-delivery">
          Out for Delivery
          <i class="status-icon circle-check"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge available-for-pickup">
          Available for pickup
          <i class="status-icon circle-check-2"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge delivered">
          Delivered
          <i class="status-icon circle-check-2"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge on-hold">
          On hold
          <i class="status-icon circle-exclaimation"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge cancelled">
          Cancelled
          <i class="status-icon circle-cancel"> </i>
        </span>
      </div>
      <br />
      <div>
        <span class="status-badge delivery-failed">
          Delivery failed
          <i class="status-icon circle-cancel"> </i>
        </span>
      </div>
    </div>
  </p-card>
  <br />
  <p-card>
    <div *ngFor="let status of statuses">
      <div
        class="completion-status"
        [ngClass]="{
          complete: status.percentage === 100,
          excess: status.percentage > 100,
          'in-progress': status.percentage > 0 && status.percentage < 100
        }"
        [ngStyle]="{ '--percentage': status.percentage }"
      >
        <ng-container *ngIf="status.percentage === 100">
          <i class="icon"></i>
        </ng-container>
        <ng-container *ngIf="status.percentage > 100">
          <i class="icon"></i>
        </ng-container>
        <ng-container *ngIf="status.percentage > 0 && status.percentage < 100">
          <div class="icon"></div>
        </ng-container>
        {{ status.percentage }} &percnt;
      </div>
    </div>
  </p-card>
  <br />
  <div>
    <p-card>
      <div class="result-container">
        <div class="col-title">Columns</div>
        <ng-container *ngFor="let row of getRows()">
          <div class="result-row">
            <ng-container *ngFor="let column of row">
              <div class="result-item">
                <i class="check-icon"></i>
              </div>
              <label for class="checked"> {{ column.name }}</label>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </p-card>
  </div>
  <br />
  <p-card>
    <span class="icon-sm"></span>
    <span class="icon-md"></span>
    <span class="icon-lg"></span>
    <span class="icon-xl"></span>
    <span class="icon-xxl"></span>
  </p-card>
  <br />
  <br />
  <div>
    <p-card>
      <rcp-add-item></rcp-add-item>
    </p-card>
  </div>
</div>
<div>
  <button
    type="button"
    pButton
    class="btn-secondary-lg"
    style="max-width: 150px"
    (click)="customDialog.showDialog()"
  >
    Cancel order
  </button>
  <rcp-cancel-order #customDialog></rcp-cancel-order>
</div>
