import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { AddItemComponent } from '../../shared/components/add-item/add-item.component';
import { CancelOrderComponent } from '../../shared/components/cancel-order/cancel-order.component';
import { DateSelectorComponent } from '../../shared/components/date-selector/date-selector.component';
import { GridComponent } from '../../shared/components/grid/grid.component';
import { OrderdetailsComponent } from '../../shared/components/orderdetails/orderdetails.component';
import { TabFilterComponent } from '../../shared/components/tab-filter/tab-filter.component';
import { PageHeadingComponent } from '../../shared/layout/page-heading/page-heading.component';
import { ReturnMetricsComponent } from '../return-metrics/return-metrics.component';
import { SlaComponent } from '../sla/summery-view/sla.component';
interface Status {
  percentage: number;
}
@Component({
  selector: 'rcp-component-reference',
  standalone: true,
  imports: [
    CardModule,
    GridComponent,
    OrderdetailsComponent,
    CommonModule,
    CancelOrderComponent,
    TabFilterComponent,
    AddItemComponent,
    ButtonModule,
    SlaComponent,
    PageHeadingComponent,
    ReturnMetricsComponent,
    DateSelectorComponent,
  ],
  templateUrl: './component-reference.component.html',
  styleUrl: './component-reference.component.scss',
})
export class ComponentReferenceComponent {
  display: boolean = false;
  statuses: Status[] = [
    { percentage: 100 },
    { percentage: 91 },
    { percentage: 111 },
  ];
  columns = [
    { name: 'Facility Id', isChecked: true },
    { name: 'Campaign ID', isChecked: true },
    { name: 'Expected Qty', isChecked: true },
    { name: 'Received Qty', isChecked: true },
    { name: 'Description', isChecked: true },
    { name: 'Item grade', isChecked: true },
    { name: 'Purchase Order Number', isChecked: true },
  ];
  getRows() {
    const selectedColumns = this.columns.filter((column) => column.isChecked);
    const rows = [];
    for (let i = 0; i < selectedColumns.length; i += 4) {
      rows.push(selectedColumns.slice(i, i + 4));
    }
    return rows;
  }
}
