import { Component, ViewEncapsulation } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
@Component({
  selector: 'rcp-cancel-order',
  standalone: true,
  imports: [ConfirmDialogModule, ButtonModule, ToastModule, DialogModule],
  templateUrl: './cancel-order.component.html',
  styleUrl: './cancel-order.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CancelOrderComponent {
  display: boolean = false;
  constructor(private confirmationService: ConfirmationService) {}
  confirm() {
    this.confirmationService.confirm({
      header: 'Cancel order 123456789019383',
      message: 'Are you sure you want to cancel this order?',
      accept: () => {
        this.onSave();
      },
      reject: () => {
        this.onCancel();
      },
    });
  }
  showDialog() {
    this.display = true;
    this.confirm();
  }
  onCancel() {
    this.display = false;
  }
  onSave() {
    this.display = false;
  }
}
