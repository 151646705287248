import { Component } from '@angular/core';

@Component({
  selector: 'rcp-no-access',
  standalone: true,
  imports: [],
  templateUrl: './no-access.component.html',
  styleUrl: './no-access.component.scss'
})
export class NoAccessComponent {
authorizedString:string = 'User not authorized! Please contact your administrator.';
}
