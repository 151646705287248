import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { authConfig } from './auth/auth-config';
import { InboundMetricsComponent } from './components/inbound-metrics/inbound-metrics.component';
import { CpRoutes } from './cp-route-constants';
import { ClientPortalService } from './services/clientPortalService/client-portal.service';
import { UserDetailsAPIPayload } from './services/clientPortalService/types/types';
import { CancelOrderComponent } from './shared/components/cancel-order/cancel-order.component';
import { ChangeAddressFormComponent } from './shared/components/change-address-form/change-address-form.component';
import { ClientWarehouseDropdownComponent } from './shared/components/client-warehouse-dropdown/client-warehouse-dropdown.component';
import { DateSelectorComponent } from './shared/components/date-selector/date-selector.component';
import { GridComponent } from './shared/components/grid/grid.component';
import { OrderdetailsComponent } from './shared/components/orderdetails/orderdetails.component';
import { PageHeadingComponent } from './shared/layout/page-heading/page-heading.component';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    DateSelectorComponent,
    ButtonModule,
    SkeletonModule,
    GridComponent,
    CardModule,
    OrderdetailsComponent,
    ChangeAddressFormComponent,
    OverlayPanelModule,
    ClientWarehouseDropdownComponent,
    ToastModule,
    CancelOrderComponent,
    RouterModule,
    PageHeadingComponent,
    InboundMetricsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'rcp-angular-js-frontend-v2';
  oAuthService = inject(OAuthService);
  cpService = inject(ClientPortalService);
  router = inject(Router);
  destroyRef = inject(DestroyRef);
  location = window.location;
  loggedInUserInfo: any;
  showHeader = false;
  userDetails: any;
  sitesFetched = false;
  userDetailsFetched = false;
  //constants used in html
  welcome = 'Welcome,';
  constructor() {
    this.initOAuth();
  }
  initOAuth(): void {
    this.oAuthService.configure(authConfig);
    this.oAuthService.setStorage(localStorage);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(
      () => {
        if (this.oAuthService.hasValidAccessToken()) {
          this.initAppFlow();
        } else {
          this.oAuthService.initImplicitFlow();
        }
      },
      (e) => {
        this.error(e);
      }
    );
  }
  initAppFlow(): void {
    this.oAuthService.loadUserProfile().then(
      (userInfo: { info: any }) => {
        const { companyName, entryUUID, firstName, lastName, mail } =
          userInfo.info;
        this.loggedInUserInfo = {
          companyName,
          entryUUID,
          firstName,
          lastName,
          mail,
        };
        this.getWareHousesList();
        this.getUserDetails();
      },
      (e) => {
        this.error(e);
      }
    );
  }
  getWareHousesList() {
    this.cpService.getWarehouses().subscribe({
      next: (data: any) => {
        const response = data?.response;
        this.cpService.allClientWareHouses$.next(response);
      },
      error: (e) => {
        this.error(e);
      },
      complete: () => {
        this.sitesFetched = true;
      },
    });
  }
  getUserDetails() {
    const UUID = this.loggedInUserInfo.entryUUID;
    const payLoad: UserDetailsAPIPayload = {
      company_name: '',
      uuid: UUID,
      search_uuid: UUID,
    };
    this.showHeader = false;
    this.cpService
      .getUserDetails(payLoad)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: any) => {
          this.userDetails = data.response;
          if (data.statusmessage === 'Success' && this.userDetails.length > 0) {
            this.storeLoggedInUserInfo(
              this.userDetails[0].uuid,
              this.userDetails[0].role_name,
              this.userDetails[0].role_acronym
            );
            this.cpService.processUserDetailsAPIResponse(this.userDetails);
            //need to remove in future
            //this.cpService.currentSelectedClient$.next(
            //  this.userDetails[0].client_acronym.trim()
            //);
            sessionStorage.setItem(
              'companyname',
              this.userDetails[0].client_acronym.trim()
            );
            //sonar-ignore
            //In future we will remove below commented code
            //this.getWareHouseDropDownList();
            this.cpService.logInUserDetails$.next(data.response);
            //sonar-ignore
            //In future we will remove below commented code
            //this.logInUserRoleAcronym = this.userDetails[0].role_acronym;
            //this.getModulesByClient(this.selectedCompany);
          } else if (
            (data.response.errorCode === 'Err_1006' &&
              data.statusmessage === 'Fail') ||
            data.response.length === 0
          ) {
            this.router.navigate(['no-access']);
          }
          this.showHeader = true;
        },
        error: (e) => {
          console.log('error while calling user details api ', e);
          this.error(e);
        },
        complete: () => {
          this.userDetailsFetched = true;
        },
      });
  }
  storeLoggedInUserInfo(UUID: string, rol: string, roleAcnym: string): void {
    this.loggedInUserInfo.UUID = UUID;
    this.loggedInUserInfo.role = rol;
    this.loggedInUserInfo.role_acc = roleAcnym;
    sessionStorage.setItem('uuid', UUID);
    this.cpService.setLoggedInUserInfo(this.loggedInUserInfo);
  }
  onClientWarehouseChange(e: { clientName: string; warehouseList: string[] }) {
    const { clientName: newClient, warehouseList } = e;
    const currentActiveClient = this.cpService.getActiveClient();
    this.cpService.currentSelectedClient$.next(newClient);
    this.cpService.currentlySelectedWarehouses$.next(warehouseList);
    sessionStorage.setItem('companyname', newClient);
    sessionStorage.setItem('selectedWareHouses', warehouseList.toString());
    /**
     * currently whenever client changes we route user to
     * first module he is having access to
     * but for time being we will route to '/' but
     * as soon as header is integrated we will route to
     * first module
     */
    if (currentActiveClient !== newClient) {
      this.router.navigate(['/']);
    }
  }
  error(e: any) {
    console.error('error occured ', e);
    this.logOut();
    this.router.navigate(['no-access']);
    this.showHeader = true;
  }
  logOut() {
    this.oAuthService.revokeTokenAndLogout();
  }
  gotoRealtimedashoard() {
    this.router.navigate([CpRoutes.RealtimeDashboard]);
  }
  gotoReportPage() {
    this.router.navigate([CpRoutes.ReportBuilder]);
  }
  goToSla() {
    this.router.navigate([CpRoutes.OntimeShipSLA]);
  }
  goToReturn() {
    this.router.navigate([CpRoutes.ReturnDetails]);
  }
  goToInventory() {
    this.router.navigate([CpRoutes.InventoryDetails]);
  }
  goToInbound() {
    this.router.navigate([CpRoutes.PurchaseOrderDetails]);
  }
  gotoHistoricDashboard() {
    this.router.navigate([CpRoutes.HistoricDashboard]);
  }
  gotoOutbound() {
    this.router.navigate([CpRoutes.OrderDetails]);
  }
}
