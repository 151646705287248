import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TagModule } from 'primeng/tag';
import { ChangeAddressFormComponent } from '../change-address-form/change-address-form.component';
interface Parcel {
  id: string;
  status: string;
}
interface Order {
  orderNumber: string;
  interfacedDate: string;
  orderedDate: string;
  clientDetails: {
    name: string;
    address: string;
  };
  emailAddress: string;
  carrier: string;
  parcelId: string;
  status: string;
}
@Component({
  selector: 'app-orderdetails',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    TagModule,
    DropdownModule,
    CommonModule,
    FormsModule,
    ChangeAddressFormComponent,
  ],
  templateUrl: './orderdetails.component.html',
  styleUrl: './orderdetails.component.scss',
  providers: [DialogService, DynamicDialogRef],
})
export class OrderdetailsComponent {
  orderid = 'Order #701215583';
  interfacedDate = 'Interfaced Date';
  ordereddate = 'Ordered Date';
  clientDetails = 'Client Detils';
  emailaddress = 'Email Address';
  carrier = 'Carrier';
  parcelid = 'Parcel ID';
  status = 'Status';
  inprocess = 'Tracking will be availbale soon';
  parcels: Parcel[] = [
    { id: 'SO2358814', status: 'Received' },
    { id: 'SO2358815', status: 'Despatched' },
    { id: 'SO2358816', status: 'In process' },
    { id: 'SO2358817', status: 'Delivered' },
    { id: 'SO2358818', status: 'On hold' },
    { id: 'SO2358819', status: 'Deliverey failed' },
    { id: 'SO2358820', status: 'Cancelled' },
  ];
  selectedParcel: Parcel | undefined;
  onParcelChange(event: any) {
    this.selectedParcel = this.parcels.find(
      (parcel) => parcel.id === event.value.id
    );
  }
  selectedOrder: any = {
    id: 1,
    orderNumber: '7012155853',
    interfacedDate: '2024-05-09',
    orderedDate: '2024-05-08',
    firstName: 'Georg ',
    lastName: 'Homenk',
    addressLine1: 'Rietheimerstrasse 8 ',
    addressLine2: ' Dürbheim',
    postcode: '78589',
    city: 'Newark',
    country: 'germany',
    emailAddress: 'info@loremepsum.com',
    carrier: 'SCHMELZ',
  };
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          this.router.getCurrentNavigation()?.extras.state?.['updatedOrder']
        ) {
          const updatedOrder =
            this.router.getCurrentNavigation()?.extras.state?.['updatedOrder'];
          this.updateOrderDetails(updatedOrder);
        }
      }
    });
  }
  openChangeAddressDialog() {
    this.router.navigate(['/changeAddress'], {
      state: { order: this.selectedOrder },
    });
  }
  updateOrderDetails(updatedOrder: any) {
    this.selectedOrder = {
      ...this.selectedOrder,
      ...updatedOrder,
    };
  }
}
