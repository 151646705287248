import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
@Component({
  selector: 'rcp-add-item',
  standalone: true,
  imports: [
    CommonModule,
    FloatLabelModule,
    DialogModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
  ],
  templateUrl: './add-item.component.html',
  styleUrl: './add-item.component.scss',
})
export class AddItemComponent {
  skuNumber: string = '';
  quantity: number | null = null;
  unitPrice: number | null = null;
  skuFound: boolean = false;
  searchAttempted: boolean = false;
  display: boolean = false;
  skuDetails: any = {};
  additem = 'Add Item';
  skuNum = 'SKU Number';
  quantitty = 'Quantity';
  unitcost = 'Unit cost price';
  skuDesc = 'SKU Description';
  clientSkuNum = 'Client SKU Number';
  availQuant = 'Available Quantities';
  noSKU_1 = 'Sorry there is no match';
  noSku_2 = 'Make sure your SKU number is correct and try again.';
  searchSKU() {
    const mockSKU = {
      sku: '29063585',
      description: 'Bronx Blanco',
      clientSku: '29063585',
      availableQuantities: 5,
    };
    this.searchAttempted = true; // Mark that a search has been attempted
    if (this.skuNumber === mockSKU.sku) {
      this.skuDetails = mockSKU;
      this.skuFound = true;
    } else {
      this.skuFound = false;
    }
  }
  cancel() {
    this.display = false;
    this.clearForm();
  }
  addLine() {
    // Logic to add the item to a list or order
    this.display = false;
  }
  clearForm() {
    this.skuNumber = '';
    this.quantity = null;
    this.unitPrice = null;
    this.skuFound = false;
    this.searchAttempted = false;
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === '') {
      this.searchSKU();
    }
  }
}
