<p-card>
  <div class="flex flex-column gap-4">
    <div>
      <div class="font-ubuntu-medium text-2xl">{{ orderid }}</div>
    </div>
    <div class="flex flex-row justify-content-between">
      <div class="font-ubuntu-medium text-sm">{{ interfacedDate }}</div>
      <div class="font-ubuntu-light text-sm">
        {{ selectedOrder.interfacedDate }}
      </div>
    </div>
    <div class="flex flex-row justify-content-between">
      <div class="font-ubuntu-medium text-sm">{{ ordereddate }}</div>
      <div class="font-ubuntu-light text-sm">
        {{ selectedOrder.orderedDate }}
      </div>
    </div>
    <div class="flex flex-column gap-1">
      <div class="flex flex-row justify-content-between">
        <div class="font-ubuntu-medium text-sm">{{ clientDetails }}</div>
        <div class="font-ubuntu-light text-sm">
          {{ selectedOrder.firstName }} {{ selectedOrder.lastName }}
        </div>
      </div>
      <div class="flex flex-row justify-content-between align-items-center">
        <button
          pButton
          class="btn-edit-icon-sm"
          label="Edit"
          (click)="openChangeAddressDialog()"
        >
          <i class="edit-icon"></i>
        </button>
        <div
          class="flex flex-row justify-content-between text-right font-ubuntu-light text-sm"
        >
          {{ selectedOrder.addressLine1 }}<br />
          {{ selectedOrder.addressLine2 }}
          {{ selectedOrder.postcode }}<br />
          {{ selectedOrder.country }}<br />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-content-between">
      <div class="font-ubuntu-medium text-sm">{{ emailaddress }}</div>
      <div class="font-ubuntu-light text-sm">
        {{ selectedOrder.emailAddress }}
      </div>
    </div>
    <div class="flex flex-row justify-content-between">
      <div class="font-ubuntu-medium text-sm">{{ carrier }}</div>
      <div class="font-ubuntu-light text-sm">{{ selectedOrder.carrier }}</div>
    </div>
    <div class="flex flex-column gap-2">
      <div class="flex flex-row gap-2">
        <div class="font-ubuntu-medium text-sm">
          {{ parcelid }}
        </div>
        <i class="questionmark-icon"> </i>
      </div>
      <div class="flex flex-row justify-content-between">
        <p-dropdown
          [options]="parcels"
          [(ngModel)]="selectedParcel"
          placeholder="Select Parcel"
          optionLabel="id"
          (onChange)="onParcelChange($event)"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
      </div>
    </div>
    <div class="flex flex-row justify-content-between align-items-center">
      <div class="font-ubuntu-medium text-sm">{{ status }}</div>
      <div
        *ngIf="selectedParcel?.status === 'Received'"
        class="status-badge received"
      >
        {{ selectedParcel?.status }}
        <i class="status-icon circle-check"> </i>
      </div>
      <div
        *ngIf="selectedParcel?.status === 'Despatched'"
        class="status-badge despatched"
      >
        {{ selectedParcel?.status }}
        <i class="status-icon circle-check"> </i>
      </div>
      <div
        *ngIf="selectedParcel?.status === 'In process'"
        class="status-badge in-process"
      >
        {{ selectedParcel?.status }}
        <i class="status-icon inprocess-icon"> </i>
      </div>
      <div
        *ngIf="selectedParcel?.status === 'Delivered'"
        class="status-badge delivered"
      >
        {{ selectedParcel?.status }}
        <i class="status-icon circle-check-2"> </i>
      </div>
      <div
        *ngIf="selectedParcel?.status === 'On hold'"
        class="status-badge on-hold"
      >
        {{ selectedParcel?.status }}
        <i class="status-icon circle-exclaimation"> </i>
      </div>
      <div
        *ngIf="selectedParcel?.status === 'Deliverey failed'"
        class="status-badge delivery-failed"
      >
        {{ selectedParcel?.status }}
        <i class="status-icon circle-cancel"> </i>
      </div>
      <div
        *ngIf="selectedParcel?.status === 'Cancelled'"
        class="status-badge cancelled"
      >
        {{ selectedParcel?.status }}
        <i class="status-icon circle-cancel"> </i>
      </div>
    </div>
    <div class="flex flex-row justify-content-between gap-2">
      <ng-container *ngIf="selectedParcel?.status === 'Received'">
        <button
          pButton
          label="Cancel order"
          class="btn-cancel-sm cnl-order"
        ></button>
      </ng-container>
      <ng-container *ngIf="selectedParcel?.status === 'In process'">
        <i class="circleinfo-icon"></i>
        <div class="in-process-color align-content-center">
          {{ inprocess }}
        </div>
      </ng-container>
    </div>
    <div class="flex flex-row justify-content-between gap-4">
      <ng-container
        *ngIf="
          selectedParcel?.status === 'Despatched' ||
          selectedParcel?.status === 'On hold' ||
          selectedParcel?.status === 'Delivered' ||
          selectedParcel?.status === 'Deliverey failed' ||
          selectedParcel?.status === 'Cancelled'
        "
      >
        <button
          pButton
          label="Parcel History"
          class="btn-secondary-lg"
        ></button>
        <button
          pButton
          label="Track your parcel"
          class="btn-primary-lg"
        ></button>
      </ng-container>
    </div>
  </div>
</p-card>
